import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Marker from "shared/components/UIElements/Marker";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

// #endregion Components

// #region Utils
import { LocationFromAddress, AddressFromLocation } from "shared/util/location";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewStopItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);

  const { InsertStop, schoolOptions } = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [itemGenerated, setItemGenerated] = useState(false);
  const refGeneratedStopId = useRef();

  const [openError, setOpenError] = useState(false);
  const [markerLocation, setMarkerLocation] = useState();
  const [mapCenter, setMapCenter] = useState();
  const [selectedMapAddress, setSelectedMapAddress] = useState();

  const ItemType = "stop";
  const APIEndPoint = "stops";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const infoToBeSent = {
        name: formState.inputs.name.value,
        address: formState.inputs.address.value,
        school: formState.inputs.school.value,
      };

      if (markerLocation) {
        infoToBeSent.location = { lat: markerLocation?.lat || 1.1, lng: markerLocation?.lng || 1.1 };
      }

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData[ItemType]) {
        InsertStop(responseData[ItemType]);
        refGeneratedStopId.current = responseData[ItemType].id;
        setItemGenerated(true);
      }
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      address: { value: "", isValid: false },
      location: { value: null, isValid: true },
      school: { value: "", isValid: false },
    },
    false
  );

  const goToItem = () => {
    props.gotoItem(refGeneratedStopId.current);
  };

  const close = () => {
    setItemGenerated(false);
    props.onClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const mapClickHandler = async (event) => {
    const currentLocation = { lat: event.lat, lng: event.lng };
    let address = null;

    try {
      address = await AddressFromLocation(currentLocation);
    } catch (error) {
      setOpenError(true);
    }
    if (address) {
      setMarkerLocation(currentLocation);
      setSelectedMapAddress(address);
      inputHandler("address", address, true);
    } else {
      setOpenError(true);
      setSelectedMapAddress("-");
      inputHandler("address", "-", false);
    }
  };

  const SetLocationFromAddress = async () => {
    let location;

    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
    setMapCenter(location);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (itemGenerated) {
    return (
      <div className="center generate-success">
        <h4>{t(`${ItemType}.itemAddedSuccessfully`)}</h4>
        <Button onClick={goToItem}> {t(`${ItemType}.goToNewItem`)}</Button>{" "}
        <Button danger onClick={() => setItemGenerated(false)}>
          {t(`${ItemType}.createNewItem`)}
        </Button>{" "}
        <Button danger onClick={close}>
          {t("close")}
        </Button>{" "}
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adres-Konum eşleşmesi bulunamadı.
        </MuiAlert>
      </Snackbar>

      <form className="item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <div className="new-button-container">
          <h3 style={{ margin: 0 }}>{t(`${ItemType}.createNewItem`)}</h3>
          <Button type="button" size="small" danger onClick={props.onClose}>
            &#60; {t("close")}
          </Button>
        </div>

        <CustomSelect options={schoolOptions} id="school" onInput={inputHandler} label={t("client")}></CustomSelect>
        <Input
          id="name"
          element="input"
          type="text"
          label={t("name")}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={t("requireField", { item: t("name") })}
          onInput={inputHandler}
        />
        <Input
          key={selectedMapAddress}
          id="address"
          element="input"
          type="text"
          label={t("address")}
          validators={[VALIDATOR_REQUIRE()]}
          initialValue={selectedMapAddress}
          errorText={t("requireField", { item: t("address") })}
          onInput={inputHandler}
        />

        <Button type="button" danger onClick={SetLocationFromAddress.bind(this, "geton")}>
          Adresten Konumu Getir
        </Button>

        {markerLocation && (
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
              }}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["satellite", "roadmap"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              center={mapCenter}
              defaultZoom={14}
              onClick={mapClickHandler.bind(this)}
            >
              <Marker Icon={RoomIcon} lat={markerLocation.lat} lng={markerLocation.lng} />
            </GoogleMapReact>
          </div>
        )}
        <div className="button-container">
          <Button type="submit" disabled={!formState.isValid}>
            {t(`${ItemType}.createNewItem`)}
          </Button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default NewStopItem;
