import React, { useContext } from "react";

// #region UI library
// import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
// #endregion Hooks

// #region Shared Components
import TabPanel from "shared/components/UIElements/TabPanel";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { CheckAuthorization } from "shared/util/util";
// #endregion Hooks

// #region Hooks
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import Transfer from "./Transfer";
import TransferRegion from "./TransferRegion";
import TransferRegionNew from "./TransferRegionNew";

import "./IkarusItem.css";

const IkarusManagement = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  //const [isLoading, setIsLoading] = React.useState(true);
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-personel--${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  if (!store.parentOptions || !store.studentOptions || !store.vehicleRoutes) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!Authorization.accessToIkarus) {
    return <div className="center">İkarus uygulaması erişiminiz bulunmuyor.</div>;
  }

  // const Item = styled(Paper)(({ theme }) => ({
  //   ...theme.typography.body2,
  //   padding: theme.spacing(1),
  //   textAlign: "center",
  //   marginTop: 20,
  //   minHeight: 80,
  //   alignItems: "center",
  //   justifyContent: "center",
  //   color: theme.palette.text.secondary,
  // }));

  return (
    <React.Fragment>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
          width: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Ikarus Transfer Tabs"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab label="Transferler" {...a11yProps(0)} />
          <Tab label="Bölgeler" {...a11yProps(1)} />
          <Tab label="Yeni Bölge Ekle" {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <Transfer></Transfer>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <TransferRegion></TransferRegion>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <TransferRegionNew></TransferRegionNew>
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

export default IkarusManagement;
