import React, { useState, useContext } from "react";

import LiveList from "components/live/LiveList";
import LiveItem from "components/live/LiveItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { CheckAuthorization } from "shared/util/util";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";
import "./Admin.css";

const Live = () => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [listShow, setListShow] = useState(true);
  const auth = useContext(AuthContext);
  const Authorization = CheckAuthorization(auth.userId);

  const pageItems = store.vehicleRoutes;

  const ItemSelectHandler = (itemid) => {
    setSelectedItemId(itemid);
    switchItemView();
  };

  const ItemUpdateHandler = (itemid) => {
    setSelectedItemId(itemid);
  };

  const switchListView = () => {
    setSelectedItemShow(false);
    setListShow(true);
  };

  const switchItemView = () => {
    setSelectedItemShow(true);
    setListShow(false);
  };

  // return <div className="center">Araç izleme uygulaması erişiminiz bulunmuyor.</div>;

  if (isLoading || !store.vehicleRoutes) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!Authorization.accessToTransfers) {
    return <div className="center">Araç izleme uygulaması erişiminiz bulunmuyor.</div>;
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className="adminpageContainer">
        {!isLoading && pageItems && listShow && (
          <div className="liveListContainer">
            <LiveList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="liveUpdateContainer">
            <LiveItem
              itemid={selectedItemId}
              onItemUpdate={ItemUpdateHandler}
              onClose={() => switchListView()}
            ></LiveItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Live;
