import React, { useState, useContext } from "react";

import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import { formatDate } from "shared/util/time";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import { sleep } from "shared/util/util";

import Grid from "@mui/material/Grid";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import Modal from "shared/components/UIElements/Modal";
import { useForm } from "shared/hooks/form-hook";
import { AuthContext } from "shared/context/auth-context";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import "./Admin.css";
import { CURRENT_EDUCATION_YEAR_ID, CURRENT_EDUCATION_YEAR_LABEL } from "shared/util/constants";

const BulkProcesses = (props) => {
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);
  const { isLoading, sendRequest } = useHttpClient();
  const updateStoreItems = store.UpdateStudents;
  const [isProcessing, setIsProcessing] = useState(false);
  const [showActivePassiveModalModal, setShowActivePassiveModalModal] = useState(false);
  const [showActivePassiveLevelModal, setShowActivePassiveLevelModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);
  const [showRealAdressModal, setShowRealAdressModal] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
    },
    false
  );

  const showActivePassiveModalHandler = () => setShowActivePassiveModalModal(true);
  const cancelActivePassiveModalHandler = () => setShowActivePassiveModalModal(false);

  const showActivePassiveLevelModalHandler = () => setShowActivePassiveLevelModal(true);
  const cancelActivePassiveLevelModalHandler = () => setShowActivePassiveLevelModal(false);

  const showSmsModalHandler = () => setShowSmsModal(true);
  const cancelSmsModalHandler = () => setShowSmsModal(false);

  const showRealAdressModalHandler = () => setShowRealAdressModal(true);
  const cancelRealAdressModalHandler = () => setShowRealAdressModal(false);

  const makeActivePassive = async (event) => {
    cancelActivePassiveModalHandler();
    event.preventDefault();
    setIsProcessing(true);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/updatestudentactivepassive`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          type: "contract",
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      await updateStoreItems();
    } catch (err) {}
    setIsProcessing(false);
    setShowConfirmation(true);
  };

  const makeActivePassiveLevel = async (event) => {
    cancelActivePassiveLevelModalHandler();
    event.preventDefault();
    setIsProcessing(true);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/updatestudentactivepassive`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          type: "level",
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      await updateStoreItems();
    } catch (err) {}
    setIsProcessing(false);
    setShowConfirmation(true);
  };

  const updateRealAdress = async (event) => {
    cancelRealAdressModalHandler();
    event.preventDefault();
    setIsProcessing(true);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/updatestudentactivepassive`,
        "POST",
        JSON.stringify({
          school: formState.inputs.school.value,
          type: "realaddress",
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      await updateStoreItems();
    } catch (err) {}
    setIsProcessing(false);
    setShowConfirmation(true);
  };

  const sendContractApprovalLink = async (event) => {
    cancelSmsModalHandler();
    event.preventDefault();
    setIsProcessing(true);

    // const school = store.schools.find((school) => school.id.toString() === formState.inputs.school.value.toString());
    const students = store.students.filter(
      (student) => student.school.toString() === formState.inputs.school.value.toString()
    );

    for (let i = 0; i < students.length; i++) {
      try {
        const contract = store.contracts.find(
          (contract) =>
            contract?.student?.id.toString() === students[i].id.toString() &&
            contract.year.toString() === CURRENT_EDUCATION_YEAR_ID
        );
        if (contract && !contract.userApproved) {
          const myuser = store.users.find((user) =>
            store.relations.find((relation) => relation.student.toString() === contract.student.id.toString())
              ? user.id.toString() ===
                store.relations
                  .find((relation) => relation.student.toString() === contract.student.id.toString())
                  .parent.toString()
              : null
          );

          if (myuser?.phonenumber) {
            console.log(i + "/" + students.length);
            let message = `Öğrenci taşıma sözleşmeniz oluşturulmuştur. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

            message = `Sözleşme onayınız için aşağıdaki bağlantıya tıklayınız. 
        https://filokar.com/gbvfdsrfdc?c=${contract.id}`;

            const phone = myuser?.phonenumber.trim();
            const date = formatDate(new Date());
            let text = message;
            let smsData = { user: myuser, phone, text, date };

            try {
              await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`, "POST", JSON.stringify(smsData), {
                "Content-Type": "application/json",
                Authorization: "Bearer " + auth.token,
              });
              await sleep(10000);
            } catch (err) {
              console.log("ERROR Inner:" + err.message);
            }
          }
        }
      } catch (err) {
        console.log("ERROR:" + err.message);
      }
    }

    setIsProcessing(false);
    setShowConfirmation(true);
  };

  const handleConfirmationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowConfirmation(false);
  };
  // if(isLoading || !store.vehicleRoutes) //HERE
  if (isLoading && false) {
    //HERE
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Modal
        show={showActivePassiveModalModal}
        onCancel={cancelActivePassiveModalHandler}
        header="Öğrencilerinizin aktif/pasif durumu değişecek"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelActivePassiveModalHandler}>
              Hayır
            </Button>
            <Button danger onClick={makeActivePassive}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>
          Sözleşmeler sekmesinde okulunuz için {CURRENT_EDUCATION_YEAR_LABEL} sözleşmesi olan öğrenciler aktif, geri
          kalan öğrenciler pasif olarak işaretlenecektir.
        </p>
        <p>Bu işlem geri alınamaz. Bu değişikliği onaylıyor musunuz?</p>
      </Modal>

      <Modal
        show={showActivePassiveLevelModal}
        onCancel={cancelActivePassiveLevelModalHandler}
        header="Öğrencilerinizin aktif/pasif durumu değişecek"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelActivePassiveLevelModalHandler}>
              Hayır
            </Button>
            <Button danger onClick={makeActivePassiveLevel}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>
          Öğrenciler sekmesinde sınıf bilgisi bulunan öğrenciler aktif, geri kalan öğrenciler pasif olarak
          işaretlenecektir.
        </p>
        <p>Bu işlem geri alınamaz. Bu değişikliği onaylıyor musunuz?</p>
      </Modal>

      <Modal
        show={showSmsModal}
        onCancel={cancelSmsModalHandler}
        header="Toplu olarak sözleşme onay sms'i gönderilecek"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelSmsModalHandler}>
              Hayır
            </Button>
            <Button danger onClick={sendContractApprovalLink}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>Muhasebe/Sözleşmeler sekmesinde kontratı onaylanmamış tüm velilere kontrat onay sms'i gönderilecek.</p>
        <p>Çok sayıda kişiye sms gönderme ihtimaliniz bulunuyor! Bu işlem geri alınamaz.</p>
        <p>Bu işlemi onaylıyor musunuz?</p>
      </Modal>

      <Modal
        show={showRealAdressModal}
        onCancel={cancelRealAdressModalHandler}
        header="Konum olan olarak girilen adresleri düzelt"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelRealAdressModalHandler}>
              Hayır
            </Button>
            <Button danger onClick={updateRealAdress}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>Sistemde öğrenci adres bilgisine, konum yazılanlar gerçek adres ile değiştirilecektir.</p>
        <p>
          Örnek: Adres bilgisi "39.27112343,37.75214642" gibi bir değer ise bu konum bilgisine karşılık gelek adres
          Google Maps'ten alınarak, öğrenci kaydına eklenecektir.
        </p>
        <p>Bu işlemi onaylıyor musunuz?</p>
      </Modal>

      <Snackbar
        open={showConfirmation}
        autoHideDuration={5000}
        onClose={handleConfirmationClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleConfirmationClose} severity="success" sx={{ width: "100%" }}>
          İşleminiz tamamlandı. Diğer sekmelere giderek verileri kontrol edebilirsiniz.
        </MuiAlert>
      </Snackbar>

      <form>
        <Grid
          sx={{
            fontsize: 16,
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "row", md: "row" },
            alignItems: "center",
            justifyContent: "flex-start",
            textAlign: "left",
            width: "50%",
            id: "report-receipts-container",
          }}
          container
          spacing={1}
        >
          <Grid item xs={12}>
            <CustomSelect
              options={store.schoolOptions}
              id="school"
              onInput={inputHandler}
              label="Kurum"
              errorText="Kurum seçimini yapınız."
              initialValid={false}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </Grid>

          {isProcessing && <LoadingSpinner asOverlay />}
          {!isProcessing && (
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <div style={{ left: "10px", position: "relative", top: "15px" }}>
                <Button type="button" disabled={!formState.isValid} onClick={showActivePassiveModalHandler}>
                  {CURRENT_EDUCATION_YEAR_LABEL} sözleşmesi olanları aktif, geri kalan öğrencileri pasif işaretle
                </Button>
              </div>
            </Grid>
          )}

          {!isProcessing && (
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <div style={{ left: "10px", position: "relative", top: "15px" }}>
                <Button type="button" disabled={!formState.isValid} onClick={showActivePassiveLevelModalHandler}>
                  Sınıfı girilmiş öğrencileri aktif, geri kalan öğrencileri pasif işaretle
                </Button>
              </div>
            </Grid>
          )}

          {!isProcessing && (
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <div style={{ left: "10px", position: "relative", top: "15px" }}>
                <Button type="button" disabled={!formState.isValid} onClick={showSmsModalHandler}>
                  Kontratı onaylanmamış velilere kontrat onay sms'i gönder
                </Button>
              </div>
            </Grid>
          )}

          {!isProcessing && (
            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <div style={{ left: "10px", position: "relative", top: "15px" }}>
                <Button type="button" disabled={!formState.isValid} onClick={showRealAdressModalHandler}>
                  Konum olarak girilen adresleri düzelt
                </Button>
              </div>
            </Grid>
          )}
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default BulkProcesses;
