import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { notificationtypes } from "shared/util/types";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";

const RelationsList = (props) => {
  const visibilityJSON = localStorage.getItem("relations_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("relations_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);
  const store = useContext(StoreContext);
  const auth = useContext(AuthContext);

  let studentHeader = "...";

  if (auth.schoolType === "1") {
    studentHeader = "Öğrenciler";
  }
  if (auth.schoolType === "2") {
    studentHeader = "Servis Bildirimleri";
  }

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "parent",
      headerName: "Bildirim Sahibi",
      width: 400,
      valueGetter: (params) => {
        try {
          return store.users.find((item) => item.id.toString() === params.row.parent.toString()).name;
        } catch (e) {
          return "";
        }
      },
    },
    {
      field: "student",
      headerName: studentHeader,
      width: 500,
      valueGetter: (params) => {
        try {
          return store.studentOptions.find((item) => item.value.toString() === params.row.student.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    {
      field: "isenabled",
      headerName: "Durum",
      width: 150,
      valueGetter: (params) => (params.row.isenabled ? "Açık" : "Kapalı"),
    },
    {
      field: "notificationtype",
      headerName: "Türü",
      width: 150,
      valueGetter: (params) =>
        notificationtypes.find((item) => item.value.toString() === params.row.notificationtype.toString()).label,
    },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("relations_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("relations_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default RelationsList;
