import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { vehicleroutetypes } from "shared/util/types";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";
import { TRAVEL_STATUS_TYPE_EXPLANATIONS } from "shared/util/types";

const LiveList = (props) => {
  const visibilityJSON = localStorage.getItem("live_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("live_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const store = useContext(StoreContext);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  let columns;

  var today = new Date();
  var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  if (store.schoolOptions && store.driverOptions) {
    columns = [
      {
        field: "school",
        headerName: "Okul",
        width: 250,
        valueGetter: (params) => store.schoolOptions.find((school) => school.value === params.row.school).label,
      },

      { field: "name", headerName: "İsim", width: 150 },
      {
        field: "type",
        headerName: "Türü",
        width: 150,
        valueGetter: (params) => vehicleroutetypes.find((item) => item.value === params.row.type).label,
      },

      {
        field: "driver",
        headerName: "Sürücü",
        width: 250,
        valueGetter: (params) => {
          try {
            return store.driverOptions.find((driver) => driver.value.toString() === params.row.driver[0].id.toString())
              .label;
          } catch {
            return "";
          }
        },
      },

      { field: "plate", headerName: "Plaka", width: 150 },

      {
        field: "status",
        headerName: date + " için durum",
        width: 200,
        // valueGetter: (params) =>
        // TRAVEL_STATUS_TYPE_EXPLANATIONS.find((exp) => exp.value === params.row.status).label,

        renderCell: (params) => {
          return (
            <>
              <div className="travelStatusLiveIndicator">
                {TRAVEL_STATUS_TYPE_EXPLANATIONS.find((exp) => exp.value === params.row.status).label}
              </div>
            </>
          );
        },
      },
    ];
  }

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      {columns && (
        <ThemeProvider theme={theme}>
          <DataGrid
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) => {
              localStorage.setItem("live_visibility", JSON.stringify(newModel));
              setColumnVisibilityModel(newModel);
            }}
            filterModel={filterModel}
            onFilterModelChange={(newModel) => {
              localStorage.setItem("live_filter", JSON.stringify(newModel));
              setFilterModel(newModel);
            }}
            key={props.items}
            density="compact"
            components={{
              Toolbar: CustomToolbar,
            }}
            rows={props.items}
            columns={columns}
            pageSize={100}
            getRowId={(r) => r.id}
            onSelectionModelChange={OnSelectionChangeHandler}
          />
        </ThemeProvider>
      )}
    </div>
  );
};

export default LiveList;
